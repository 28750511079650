import { createValidationObjectSchema } from '@/utils/validations';

export const UserSchema = createValidationObjectSchema({
  roles: {
    required: true,
    errorTextFieldLabel: 'Role',
  },
  email: {
    format: 'email',
    required: true,
  },
  first_name: {
    required: true,
  },
  last_name: {
    required: true,
  },
});
