
import { defineComponent } from 'vue';
import UserModal from '@/views/new-design/pages/Settings/Users/UserModal.vue';
// import UserManagerModal from '@/views/new-design/pages/Settings/Users/AddUserModal.vue';
// import EditUserModal from '@/views/new-design/pages/Settings/Users/UpdateUserModal.vue';
import { mapGetters, mapActions } from 'vuex';
import {
  Getters as ManageUserGetters,
  Actions as ManageUserActions,
  Mutations as ManageUserMutations,
} from '@/store/enums/ManageUserEnums';
import { Getters as AuthGetter } from '@/store/enums/AuthEnums';
import toasts from '@/utils/toasts';
import DataTable from '@/components/DataTable.vue';
import _ from 'lodash';
import Navigator from '@/views/new-design/layout/Navigator.vue';

export default defineComponent({
  components: {
    UserModal,
    DataTable,
    //UserManagerModal,
    //EditUserModal,
    Navigator,
  },
  data() {
    return {
      formErrors: {},
      addingUser: false,
      editingUser: false,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Role', value: 'roles' },
        { text: 'Action', value: 'action', align: 'end' },
      ],
      currentPage: 1,
      totalPages: 0,
      perPage: 15,
      debounceGetUsers: Function(),
      selectedUser: {},
      loading: false,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetter.GET_AUTH_USER,
      getUserList: ManageUserGetters.GET_USER_LIST,
      getErrors: ManageUserGetters.GET_ERROR,
      getUserListPagination: 'userListPagination',
    }),
  },
  async mounted() {
    await this.fetchUserList();
    this.setPagination();
  },
  created() {
    this.debounceGetUsers = _.debounce(() => {
      this.fetchUsers();
    }, 1000);
  },
  watch: {
    currentPage() {
      this.debounceGetUsers();
    },
    search() {
      this.debounceGetUsers();
    },
  },
  methods: {
    ...mapActions({
      fetchUserList: ManageUserActions.FETCH_USER_LIST,
      addUserList: ManageUserActions.ADD_USER_LIST,
      setErrors: ManageUserMutations.SET_ERROR,
      deleteUserList: ManageUserActions.DELETE_USER_LIST,
      updateUserList: ManageUserActions.EDIT_USER_FROM_LIST,
    }),
    async fetchUsers() {
      await this.fetchUserList(this.currentPage).catch((err) => {
        console.log(err.response.data.message);
      });
    },
    async setPagination() {
      this.currentPage = this.getUserListPagination?.current_page;
      this.totalPages = this.getUserListPagination?.total_pages;
      this.perPage = this.getUserListPagination.per_page;
    },
    deleteUser(id) {
      if (id === this.authUser.id) return;

      toasts.confirm('Are you sure to remove user?', (response) => {
        if (response.isConfirmed) {
          return this.deleteUserList(id)
            .then(() => {
              toasts.success('User has been deleted successfully.');
              this.debounceGetUsers();
            })
            .catch(() => {
              const { errors, message } = this.getErrors;
              if (errors) {
                this.formErrors = errors;
                return;
              }
              toasts.error(errors, message);
            });
        }
      });
    },
    createNewUser() {
      this.selectedUser = {};
      this.showModal = true;
    },
    editUser(user) {
      this.selectedUser = user;
      this.showModal = true;
    },
    handleFormSubmitted() {
      this.showModal = false;
      this.debounceGetUsers();
    },
    onPageChanged(page) {
      this.currentPage = page;
    },
  },
});
